import React from "react";
import { Link } from "react-router-dom";
import "../css/app.css";

const Contact = (props) => {
  return (
    <div className="container">
      <div className="card flex-center" style={{ backgroundColor: "black" }}>
        <div className="card-image">
          <img
            className="Contact-norehill"
            src="images/norehill-black.png"
            alt="Norehill"
          ></img>
        </div>
        <div className="card-content">
          <p><i> 
          "We specializes in IT consultancy, offering expert guidance and innovative solutions in the realm of software development, solution architecture and project management. Additionally, we pride ourselves on our artistically crafted graphic design services, catering to a diverse range of digital and analogue art needs."
          </i></p>
          <br />
          <div className="content">
            <div className="Contact-adress">
              <div className="adr">
                <p>
                  <strong>Email:</strong>{" "}
                  <a className="email" href="mailto:contact@norehill.com">
                    contact@norehill.com
                  </a>
                </p>
              </div>

              <p>
                <strong>Address:</strong>
                <br />
                <span className="fn organization-name">
                  Norehill Solultions
                </span>
                <br />
                <span className="adr">
                  <span className="street-address">Norehillsvägen 21</span>
                  <br />
                  <span className="postal-code">134 92</span>{" "}
                  <span className="locality">Tynningö</span>
                  <br />
                  <span className="country">Sweden</span>
                </span>
              </p>
            </div>
          </div>
        </div>
        <footer className="card-footer">
        <a href="https://www.norehill.art/" className="card-footer-item">
          Art & Design
        </a>
        </footer>
        <footer className="card-footer">
          <Link to="/" className="card-footer-item">
            Home
          </Link>
        </footer>
      </div>
    </div>
  );
};

export default Contact;
